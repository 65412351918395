@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.portfolio {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 69px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 4%;
}
@media only screen and (max-width: 480px) {
  .portfolio {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .portfolio {
    gap: 33px;
  }
}

.card {
  width: 100%;
  height: 600px;
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.card-front {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-back {
  background-color: white;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E29624;
  display: flex;
  flex-direction: column;
  color: #4C3D3D;
  font-family: "Bacasime Antique", serif;
}
.card-back .card-back-title {
  text-align: center;
  border-bottom: solid 2px #E29624;
  font-size: 36px;
  font-weight: 700;
}
.card-back .card-back-texte {
  font-size: 20px;
  font-weight: 600;
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 480px) {
  img {
    height: 100%;
  }
}/*# sourceMappingURL=portfolio.css.map */