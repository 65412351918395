@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.overlay {
  position: absolute;
  top: 18%;
  right: 35%;
  border: 2px solid #E29624;
  width: 19%;
  z-index: 3;
  background-color: white;
  font-size: 20px;
  color: #4C3D3D;
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .overlay {
    top: 10%;
    right: 0%;
    width: 123%;
    font-size: 17px;
  }
}
@media only screen and (max-width: 480px) {
  .overlay {
    top: 1%;
    right: 0%;
    width: 127%;
    font-size: 17PX;
    height: 98%;
  }
}
.overlay p {
  margin: 10px;
  margin-left: 10px;
}
.overlay .mensuration {
  border-bottom: none;
}/*# sourceMappingURL=mensuration.css.map */