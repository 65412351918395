@import '../variables.scss';

.champ{
    margin-bottom: 5%;
    font-family: 'Bacasime Antique', serif;

    input, textarea{
        border-bottom: solid 2px $Color;
        border-top: none;
        border-right: none;
        border-left: none;
        margin-left: 4%;
        font-size: $subTitle;
        font-family: 'Bacasime Antique', serif;
        color: $primaryColor;
        font-weight: 400;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 18px;
        }

        &:focus-visible{
            outline: none;
        }
    }

    label{
        font-size: $subTitle;
        color: $primaryColor;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 18px;
        }
    }
}

.button-formulaire{
    display: block;
    font-size: $subTitle;
    background-color: transparent;
    border: solid 2px $Color;
    font-family: 'Bacasime Antique', serif;
    width: 50%;
    margin-left: 23%;
    color: $primaryColor;
    cursor: pointer;

    &:focus-visible{
        outline: none;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
        font-size: 24px;
        margin-top: 15%;
    }
}

.nom{
    width: 54%;
}

.prenom, .message{
    width: 48%;
}

.mail{
    width: 56%;
}

.tel{
    width: 58%;
}

#checkbox{
    margin-left: 0;
}

#texte-info{
    font-size: 25px;
    margin-left: 2%;

    @media only screen and (max-width: $breakpoint-mobile) {
        font-size: 18px;
    }
}

.logo-info{
    color: $Color;
    font-size: 18px;
    cursor: pointer;
}

.confirmation-message{
    background-color: #f4d8ae;
    position: absolute;
    top: 49%;
    width: 39%;
    height: 82%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $primaryColor;

    @media only screen and (max-width: $breakpoint-mobile) {
        top: 21%;
        width: 89%;
        align-items: center;
    }

    @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
        top: 27%;
        width: 82%;
        height: 52%;
    }

    h2{
        margin-top: 15%;
        font-size: $subTitle;
        padding-left: 30px;

        @media only screen and (max-width: $breakpoint-mobile) {
            padding-left: 0px;
        }
    }

    p{
        padding-left: 30px;
        padding-right: 30px;
        height: 52%;
        font-size: $subTitle;
    }
}