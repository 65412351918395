@import '../variables.scss';

.portfolio{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 69px;
    @include container();
    margin-bottom: 4%;

    @media only screen and (max-width: $breakpoint-mobile) {
        display: flex;
        flex-direction: column;
    }

    @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
        gap: 33px;
    }
}

.card {
    width: 100%;
    height: 600px;
    perspective: 1000px;
    cursor: pointer;
}
  
.card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s;
}
  
.card:hover .card-inner {
    transform: rotateY(180deg);
}
  
.card-front,.card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}
  
.card-front {
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.card-back {
    background-color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $Color;
    display: flex;
    flex-direction: column;
    color: $primaryColor;
    font-family: 'Bacasime Antique', serif;

    .card-back-title{
        text-align: center;
        border-bottom: solid 2px $Color;
        font-size: $subTitle;
        font-weight: 700;
    }

    .card-back-texte{
        font-size: $texte;
        font-weight: 600;
    }
}
  
.card img {
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: $breakpoint-mobile) {
    img{
        height: 100%;
    }
}