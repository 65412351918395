@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 70px;
  font-family: "Bacasime Antique", serif;
}
@media only screen and (max-width: 480px) {
  .header {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.header a {
  text-decoration: none;
}
.header .brandname {
  width: 50%;
}
.header .brandname .brandname-name {
  font-size: 48px;
  margin-bottom: 10px;
  color: #4C3D3D;
}
@media only screen and (max-width: 480px) {
  .header .brandname .brandname-name {
    font-size: 24px;
  }
}
.header .brandname .brandname-work {
  font-size: 36px;
  color: #E29624;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .header .brandname .brandname-work {
    font-size: 24px;
  }
}
.header .menu {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 28px;
  width: 58%;
  color: #4C3D3D;
  padding-left: 13%;
}
.header .menu .menu-item {
  border-bottom: #4C3D3D solid 2px;
  padding-bottom: 10px;
  margin: 0;
  padding-top: 70px;
  color: #4C3D3D;
}
.header .menu .mensuration {
  cursor: pointer;
}
.header .menu .about:hover, .header .menu .avis:hover, .header .menu .contact:hover, .header .menu .mensuration:hover, .header .menu .folio:hover {
  border-bottom: solid 2px #E29624;
  color: #E29624;
}
.header .menu a {
  color: #E29624;
}
.header .menu a .logo-instagram {
  font-size: 30px;
}

.title-name {
  color: black;
}/*# sourceMappingURL=header.css.map */