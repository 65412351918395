@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.champ {
  margin-bottom: 5%;
  font-family: "Bacasime Antique", serif;
}
.champ input, .champ textarea {
  border-bottom: solid 2px #E29624;
  border-top: none;
  border-right: none;
  border-left: none;
  margin-left: 4%;
  font-size: 36px;
  font-family: "Bacasime Antique", serif;
  color: #4C3D3D;
  font-weight: 400;
}
@media only screen and (max-width: 480px) {
  .champ input, .champ textarea {
    font-size: 18px;
  }
}
.champ input:focus-visible, .champ textarea:focus-visible {
  outline: none;
}
.champ label {
  font-size: 36px;
  color: #4C3D3D;
}
@media only screen and (max-width: 480px) {
  .champ label {
    font-size: 18px;
  }
}

.button-formulaire {
  display: block;
  font-size: 36px;
  background-color: transparent;
  border: solid 2px #E29624;
  font-family: "Bacasime Antique", serif;
  width: 50%;
  margin-left: 23%;
  color: #4C3D3D;
  cursor: pointer;
}
.button-formulaire:focus-visible {
  outline: none;
}
@media only screen and (max-width: 480px) {
  .button-formulaire {
    font-size: 24px;
    margin-top: 15%;
  }
}

.nom {
  width: 54%;
}

.prenom, .message {
  width: 48%;
}

.mail {
  width: 56%;
}

.tel {
  width: 58%;
}

#checkbox {
  margin-left: 0;
}

#texte-info {
  font-size: 25px;
  margin-left: 2%;
}
@media only screen and (max-width: 480px) {
  #texte-info {
    font-size: 18px;
  }
}

.logo-info {
  color: #E29624;
  font-size: 18px;
  cursor: pointer;
}

.confirmation-message {
  background-color: #f4d8ae;
  position: absolute;
  top: 49%;
  width: 39%;
  height: 82%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4C3D3D;
}
@media only screen and (max-width: 480px) {
  .confirmation-message {
    top: 21%;
    width: 89%;
    align-items: center;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .confirmation-message {
    top: 27%;
    width: 82%;
    height: 52%;
  }
}
.confirmation-message h2 {
  margin-top: 15%;
  font-size: 36px;
  padding-left: 30px;
}
@media only screen and (max-width: 480px) {
  .confirmation-message h2 {
    padding-left: 0px;
  }
}
.confirmation-message p {
  padding-left: 30px;
  padding-right: 30px;
  height: 52%;
  font-size: 36px;
}/*# sourceMappingURL=formulaire.css.map */