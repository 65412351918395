@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.avis-section {
  margin-left: 70px;
  margin-right: 70px;
  font-family: "Bacasime Antique", serif;
}
@media only screen and (max-width: 480px) {
  .avis-section {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.avis-section .avis-tittle {
  text-align: center;
  color: #4C3D3D;
  border-bottom: solid 2px #E29624;
  width: 53%;
  margin-left: 24%;
  font-size: 36px;
  margin-bottom: 6%;
}
@media only screen and (max-width: 480px) {
  .avis-section .avis-tittle {
    font-size: 14px;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .avis-section .avis-tittle {
    font-size: 24px;
    width: 100%;
    margin-left: 0px;
  }
}
.avis-section .avis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
@media only screen and (max-width: 480px) {
  .avis-section .avis {
    width: 100%;
  }
}
.avis-section .avis-right {
  margin-left: 50%;
}
@media only screen and (max-width: 480px) {
  .avis-section .avis-right {
    margin-left: 0px;
  }
}
.avis-section .texte {
  font-size: 20px;
  color: #7A7979;
}
@media only screen and (max-width: 480px) {
  .avis-section .texte {
    font-size: 16px;
    text-align: center;
  }
}
.avis-section .author-avis {
  text-decoration: none;
  color: #4C3D3D;
}/*# sourceMappingURL=avis.css.map */