@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

.overlay-filter {
  position: absolute;
  z-index: 2;
  background-color: black;
  opacity: 0.44;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.carrousel {
  height: 100vh;
  overflow: hidden;
}

.title-name {
  position: absolute;
  z-index: 2;
  top: 0px;
  font-size: 48px;
  margin-left: 55px;
  color: white;
  font-family: "Bacasime Antique", serif;
}
@media only screen and (max-width: 480px) {
  .title-name {
    font-size: 35px;
    margin-left: 5px;
  }
}

.info {
  position: absolute;
  top: 80%;
  color: white;
  font-size: 28px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
@media only screen and (max-width: 480px) {
  .info {
    top: 75%;
  }
}
.info .quote-text {
  margin: 0;
  font-family: "Dancing Script", cursive;
}
@media only screen and (max-width: 480px) {
  .info .quote-text {
    font-size: 24px;
    text-align: center;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .info .quote-text {
    text-align: center;
    font-size: 30px;
  }
}
.info .quote-author {
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: "Bacasime Antique", serif;
}
@media only screen and (max-width: 480px) {
  .info .quote-author {
    font-size: 24px;
  }
}
.info .button-portfolio {
  width: 29%;
  display: flex;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 480px) {
  .info .button-portfolio {
    width: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .info .button-portfolio {
    width: 100%;
  }
}
.info .button-portfolio #btnPortfolioAccess {
  display: block;
  border: white solid;
  background-color: transparent;
  color: white;
  font-size: 33px;
  cursor: pointer;
  font-family: "Bacasime Antique", serif;
}
@media only screen and (max-width: 480px) {
  .info .button-portfolio #btnPortfolioAccess {
    padding: 4%;
    font-size: 38px;
  }
}
.info .logo-instagram {
  margin-top: 20px;
  color: white;
}/*# sourceMappingURL=homepage.css.map */