@import '../variables.scss';


img{
    width: 100%;
    height: 100%;

    @media only screen and (max-width: $breakpoint-mobile) {
        height: 100vh;
    
    }

    @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
        height: 100vh;
    }
}


.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > div {
    min-height: 100vh;
}

.slick-slide > div > div {
    overflow: hidden;
    position: relative;

    & > img {
        min-height: 100%;
        min-width: 100%;
        max-height: 150%;
        max-width: 150%;
        width: auto;   
        height: auto;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);

    }
}


body{
    margin: 0;
}

button{
    display: none;
}
