@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 480px) {
  img {
    height: 100vh;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  img {
    height: 100vh;
  }
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > div {
  min-height: 100vh;
}

.slick-slide > div > div {
  overflow: hidden;
  position: relative;
}
.slick-slide > div > div > img {
  min-height: 100%;
  min-width: 100%;
  max-height: 150%;
  max-width: 150%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

body {
  margin: 0;
}

button {
  display: none;
}/*# sourceMappingURL=carrousel.css.map */