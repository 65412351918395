@import url("https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Dancing+Script&display=swap");
.lien {
  text-decoration: none;
  color: #4C3D3D;
  font-weight: 700;
}

footer {
  background-color: #4C3D3D;
  margin-left: 70px;
  margin-right: 70px;
}
@media only screen and (max-width: 480px) {
  footer {
    margin-left: 30px;
    margin-right: 30px;
  }
}
footer p {
  color: white;
  font-size: 20px;
  padding: 0.5% 2% 0% 2%;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  footer p {
    font-size: 16px;
  }
}/*# sourceMappingURL=footer.css.map */