@import '../variables.scss';

.header{
    @include display(space-between, center);
    @include container();
    margin-bottom: 70px;
    font-family: 'Bacasime Antique', serif;

    @media only screen and (max-width: $breakpoint-mobile) {
        margin-left: 30px;
        margin-right: 30px;
    }

    a{
        text-decoration: none;
    }

    .brandname{
        width: 50%;

        .brandname-name{
            font-size: $title;
            margin-bottom: 10px;
            color: $primaryColor;

            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 24px;
            }
        }

        .brandname-work{
            font-size: $subTitle;
            color: $Color;
            margin: 0;

            @media only screen and (max-width: $breakpoint-mobile) {
                font-size: 24px;
            }
        }
    }
    
    .menu{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-size: 28px;
        width: 58%;
        color: $primaryColor;
        padding-left: 13%;

        .menu-item{
            border-bottom: $primaryColor solid 2px;
            padding-bottom: 10px;
            margin: 0;
            padding-top: 70px;
            color: $primaryColor;
        }

        .mensuration{
            cursor: pointer;
        }

        .about:hover, .avis:hover, .contact:hover, .mensuration:hover, .folio:hover{
            border-bottom: solid 2px $Color;
            color: $Color;
        }

        a{
            color: $Color;

            .logo-instagram{
                font-size: 30px;
            }
        }
    }
}

.title-name{
    color: black;
}