@import '../variables.scss';

.apropos{
    @include container();
    font-family: 'Bacasime Antique', serif;

    @media only screen and (max-width: $breakpoint-mobile) {
        margin-left: 30px;
        margin-right: 30px;
    }

    .title-apropos{
        text-align: center;
        font-size: $subTitle;
        font-weight: bold;
        border-bottom: solid 2px $Color;
        width: 69%;
        margin-left: 190px;
        color: $primaryColor;
        margin-bottom: 100px;
        margin-top: 125px;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 14px;
            margin-top: 0px;
            margin-left: 0px;
            width: 100%;
            margin-bottom: 20px;
        }

        @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
            font-size: 24px;
            width: 100%;
            margin-left: 0px;
        }
    }

    .section{
        @include display(space-between, flex-start);
        margin-bottom: 50px;

        @media only screen and (max-width: $breakpoint-mobile) {
            flex-direction: column;
        }

        .texte{
            width: 65%;
            color: $primaryColor;

            @media only screen and (max-width: $breakpoint-mobile) {
                width: 100%;;
            }


            h3{
                font-size: 25px;

                @media only screen and (max-width: $breakpoint-mobile) {
                    font-size: 18px;
                }
            }

            p{
                font-size: $texte;
                @media only screen and (max-width: $breakpoint-mobile) {
                    font-size: 16px;
                }
            }
        }

        .image2{
            width: 33%;
            height: 100%;

            @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
                margin-right: 4%;
            }

            @media only screen and (max-width: $breakpoint-mobile) {
                width: 100%;
            }
        }

        .photo1{
            height: 56vh;

            @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
                margin-left: 4%;
            }

        }

        .photo3{
            height: 50vh;

            @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
                margin-left: 4%;
                display: contents;
            }
        }

        .photo2{
            width: 66%;
            height: 100%;

            @media only screen and (max-width: $breakpoint-mobile) {
                width: 100%;
            }

            @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
                width: 100%;
            }
        }
        
    }

    .liste{
        font-size: $texte;
        margin-bottom: 50px;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 16px;
        }

        ul {
            list-style-type: none;
        }

        ul li {
            position: relative;
        }

        ul li:before {
            content: "♥";
            display: block;
            position: absolute;
            left: -20px;
            top: 0;
            color: $Color;
        }
    }

    .line{
        border-bottom: solid 2px $Color;
        width: 50%;
        margin-left: 25%;
        margin-bottom: 100px;
    }
    
}

.poeme{
    text-align: center;
  font-size: 24px;
  padding-bottom: 100px;
  margin-top: -134px;

    
    @media only screen and (max-width: $breakpoint-mobile) {
        margin-top: 0px;;
    }
}

.image{
    @media only screen and (max-width: $breakpoint-mobile) {
        display: contents;
    }
}