@import '../variables.scss';

footer {
    background-color: $primaryColor;
    @include container();

    @media only screen and (max-width: $breakpoint-mobile) {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    p {
        color: white;
        font-size: 20px;
        padding: 0.5% 2% 0% 2%;
        margin: 0;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 16px;
        }
    }
}
  
