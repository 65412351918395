@import '../variables.scss';

.overlay-filter {
    position: absolute;
    z-index: 2;
    background-color: black;
    opacity: 0.44;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.carrousel{
    height: 100vh;
    overflow: hidden;
}

.title-name{
    position: absolute;
    z-index: 2;
    top: 0px;
    font-size: $title;
    margin-left: 55px;
    color: white;
    font-family: 'Bacasime Antique', serif;

    @media only screen and (max-width: $breakpoint-mobile) {
        font-size: 35px;
        margin-left: 5px;
    }
}

.info{
    position: absolute;
    top: 80%;
    color: white;
    font-size: 28px;
    z-index: 2;
    @include display(center, center);
    flex-direction: column;
    width: 100vw;

    @media only screen and (max-width: $breakpoint-mobile) {
        top: 75%;
    }

    .quote-text{
        margin: 0;
        font-family: 'Dancing Script', cursive;
        

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 24px;
            text-align: center;
        }

        @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
            text-align: center;
            font-size: 30px;
        }
    }

    .quote-author{
        margin-top: 10px;
        margin-bottom: 15px;
        font-family: 'Bacasime Antique', serif;

        @media only screen and (max-width: $breakpoint-mobile) {
            font-size: 24px;
        }
    }

    .button-portfolio{
        width: 29%;
        display: flex;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;

        @media only screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }

        @media only screen and (min-width: $breakpoint-mobile-plus-one) and (max-width: $breakpoint-tablet) {
            width: 100%;
        }

        #btnPortfolioAccess{
            display: block;
            border: white solid;
            background-color: transparent;
            color: white;
            font-size: 33px;
            cursor: pointer;
            font-family: 'Bacasime Antique', serif;

            @media only screen and (max-width: $breakpoint-mobile) {
                padding: 4%;
                font-size: 38px;
            }

        }
    }

    .logo-instagram{
        margin-top: 20px;
        color: white;
    }
}
